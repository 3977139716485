import { getJWTDecoded, writeLog } from "../../helpers/helpers";

export async function logFileUpload(metadata: object) {
  const { error } = metadata as { error?: any };
  if (error) {
    // local debugging
    console.error(metadata);

    // JSON.stringify() of a JavaScript Error returns only {}, so preserve useful fields
    if (error instanceof Error) {
      (metadata as { error?: any }).error = JSON.stringify({
        message: error.message,
        stack: error.stack,
      });
    }
  } else {
    // local debugging
    console.log(metadata);
  }

  const userInfo = getJWTDecoded();
  const userName = `${userInfo.firstName} ${userInfo.lastName}`;

  // production debugging via telemetry
  // Had trouble getting Google OAuth scope for logging, plus most users may not have access to hit this (GCP) endpoint,
  // replacing with PBEEJ API log dump (below)
  // await gapi.client.request({
  //   method: 'POST',
  //   path: 'https://logging.googleapis.com/v2/entries:write',
  //   body: {
  //     entries: [
  //       {
  //         logName: 'projects/pbeej-343804/logs/file-upload',
  //         resource: {
  //           type: 'logging_bucket',
  //           labels: {},
  //         },
  //         jsonPayload: {
  //           metadata,
  //           userName,
  //         },
  //       },
  //     ],
  //   },
  // });
  await writeLog({
    metadata,
    userName,
  });
}