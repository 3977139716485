import { logFileUpload } from "./Logging";

export enum GoogleOperationStatus {
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  SKIPPED = 'SKIPPED',
};

export default class GoogleClient {
  static AddedContentSharedDriveId: string = "0AChrdAv4MJNZUk9PVA";
  static ParentalContentSharedDriveId: string = "0AJlTgzz2jZ2gUk9PVA";
  static PBEEJApiServiceAccountEmail: string = "workspace-account@pbeej-343804.iam.gserviceaccount.com";

  static async addCollaborators(fileId: string, collaboratorsEmails: string[]): Promise<void> {
    const metadata = { operation: 'GoogleClient.addCollaborators', fileId, collaboratorsEmails };
    await Promise.all(collaboratorsEmails.map((email) =>
      gapi.client.drive.permissions.create({
        fileId,
        emailMessage: "You've been added as a collaborator on a PBEEJ document!",
        sendNotificationEmail: true,
        supportsAllDrives: true,
      }, {
        emailAddress: email,
        role: 'writer',
        type: 'user'
      })
    ))
    .then((res) => { this.logCompletion(metadata); return res; })
    .catch((err) => { this.logFailure(metadata, err); throw err; });
  };

  static async addServiceAccountAsCollaborator(fileId: string): Promise<void> {
    const metadata = { operation: 'GoogleClient.addServiceAccountAsCollaborator', fileId };
    await GoogleClient.addCollaborators(fileId, [this.PBEEJApiServiceAccountEmail])
    .then((res) => { this.logCompletion(metadata); return res; })
    .catch((err) => { this.logFailure(metadata, err); throw err; });
  }

  static async removeAllCollaborators(fileId: string): Promise<any> {
    const collaboratorPermissions = await gapi.client.drive.permissions.list({
      fileId,
      supportsAllDrives: true,
    }).then((response) => {
      const allPermissions = response.result.permissions ?? [];
      return allPermissions.filter((permission) =>
        permission.type === 'user'
        && permission.role !== 'owner'
        && permission.emailAddress !== this.PBEEJApiServiceAccountEmail
      );
    });
    const metadata = { operation: 'GoogleClient.removeAllCollaborators', fileId, collaboratorPermissions };
    await Promise.all(collaboratorPermissions.map((permission) => gapi.client.drive.permissions.delete({
      fileId,
      permissionId: permission.id ?? "",
      supportsAllDrives: true,
    })))
    .then((res) => { this.logCompletion(metadata); return res; })
    .catch((err) => { this.logFailure(metadata, err); throw err; });
  }

  static async addDomainReadPermission(fileId: string): Promise<any> {
    const metadata = { operation: 'GoogleClient.addDomainReadPermission', fileId };
    return gapi.client.drive.permissions.create({
      fileId,
      supportsAllDrives: true,
    }, {
      domain: 'pbeej.com',
      role: 'reader',
      type: 'domain',
    })
    .then((res) => { this.logCompletion(metadata); return res; })
    .catch((err) => { this.logFailure(metadata, err); throw err; });
  }

  static async removeDomainReadPermission(fileId: string): Promise<any> {
    const metadata = { operation: 'GoogleClient.removeDomainReadPermission', fileId };
    const response = await gapi.client.drive.permissions.list({
      fileId,
      supportsAllDrives: true,
    });
    const domainPermission = response.result.permissions?.find(permission => permission.type === "domain");
    if (domainPermission) {
      return gapi.client.drive.permissions.delete({
        fileId,
        permissionId: domainPermission.id ?? "",
        supportsAllDrives: true,
      })
      .then((res) => { this.logCompletion(metadata); return res; })
      .catch((err) => { this.logFailure(metadata, err); throw err; });
    } else {
      this.logSkip(metadata, 'no domain read permission found to remove');
    }
  }

  static async isOnSharedDrive(fileId: string): Promise<boolean> {
    var fileResponse = await gapi.client.drive.files.get({
      fileId,
      supportsAllDrives: true,
    });
    const { driveId } = fileResponse.result;
    return driveId?.length > 0;
  }

  static async moveToSharedDrive(fileId: string, isParentalContent = false, isParent = false): Promise<any> {
    const metadata = { operation: 'GoogleClient.moveToSharedDrive', fileId, isParentalContent, isParent };
    await gapi.client.drive.files.update({
      fileId,
      resource: {},
      supportsAllDrives: true,
      // Note: addParents refers to adding "parent" folder (in this case, a Shared Drive) to a Google Drive item, whereas isParentalContent is a PBEEJ concept :)
      addParents: isParentalContent
        ? this.ParentalContentSharedDriveId
        : this.AddedContentSharedDriveId,
      // PBEEJ children don't have access to Parental Content drive and thus cannot remove it as the Shared Drive holding this file.
      // Of course, they could never have added a file to the Parental Content drive in the first place,
      // so all they can / need to do is move files from MyDrive to Added Content shared drive and keep them there,
      // making "removeParents" necessary / possible for parents only.
      ...(isParent && {
        removeParents: isParentalContent
          ? this.AddedContentSharedDriveId
          : this.ParentalContentSharedDriveId,
      }),
    })
    .then((res) => { this.logCompletion(metadata); return res; })
    .catch((err) => { this.logFailure(metadata, err); throw err; });
  }

  static async deleteMyDriveFile(fileId: string): Promise<any> {
    const metadata = { operation: 'GoogleClient.deleteMyDriveFile', fileId };
    return gapi.client.drive.files.delete({
      fileId,
    })
    .then((res) => { this.logCompletion(metadata); return res; })
    .catch((err) => { this.logFailure(metadata, err); throw err; });
  }

  static logCompletion(metadata: object) {
    // best effort; do not await
    logFileUpload({ status: GoogleOperationStatus.COMPLETED, ...metadata }).catch(console.error);
  }

  static logFailure(metadata: object, err: any) {
    // best effort; do not await
    logFileUpload({ status: GoogleOperationStatus.FAILED, error: err, ...metadata }).catch(console.error);
  }

  static logSkip(metadata: object, reason: string) {
    // best effort; do not await
    logFileUpload({ status: GoogleOperationStatus.SKIPPED, reason, ...metadata }).catch(console.error);
  }
};
