import { useCallback, useEffect, useState, useMemo, useContext } from "react";
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Checkbox,
  Button,
  Container,
  Stack,
  TextField,
  FormControlLabel,
  FormHelperText,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Controller, useForm } from "react-hook-form";
import {
  createCollection,
  createNewContent,
  getContentAll,
  getTagList,
  saveDetails,
  deleteContent,
  getJWTDecoded,
  getCollaboratorsEmails,
  deleteSharedDriveFile,
} from "../../helpers/helpers";
import { useNavigate, createSearchParams, useLocation, useParams } from "react-router-dom";
import ReactRouterPrompt from "react-router-prompt";
import ContentStatus from "./ContentStatus";
import { getParams } from "../../helpers/discover";
import Collection from "./Collection";
import Tag from "../../models/Tag";
import ExistingContent from "./ExistingContent";
import Collaborator from "../../models/Collaborator";
import KeywordsAndAgeRanges from "./KeywordsAndAgeRanges";
import { CloseConfirmation } from "../util/CloseConfirmation";
import { DeleteConfirmation } from "../util/DeleteConfirmation";
import AlertInfo from "../../models/AlertInfo";
import { AlertType } from "../../models/enums/AlertType";
import GoogleClient from "../../services/Google/GoogleClient";
import { addProjectContent, getProjectDetails } from "../../helpers/projects";
import { SourceType } from "../../models/enums/SourceType";
import AuthContextValue from "../../models/AuthContextValue";
import { AuthContext } from "../../AuthContext";
import { useAppContext } from "../util/AppContextHook";
import AdultOnly from "../shared/AdultOnly";
import { isYouTubeChannelLink } from "../util/LinkUtils";
import { YouTube } from "@pbeejcom/safeyt-video-editor";

export interface AddFormValues {
  availability: string;
  author: string;
  free: boolean;
  parentGuidanceRequired: boolean;
  parentGuidanceReason: string;
  statusCode: string;
  contentType: string;
  link: string;
  title: string;
  description: string;
  sourceType: string | null;
  sourceId: string | null;
  selectedKeywords: Set<Tag>;
  newKeywords?: string[];
  selectedAgeRanges: Set<number>;
  collectionItems?: number[];
  collaborators?: Collaborator[];
}

interface AddFormGoogleValues {
  contentType: string;
  link: string;
  parentalGuidance: boolean;
  statusCode: string;
  collaborators: number[];
}

interface AddFormGoogleView extends AddFormGoogleValues {
  fileId: string;
  resourceHasChanged: boolean;
  statusCodeHasChanged: boolean;
  isPublic: boolean;
  isParentalContent: boolean;
  isParentalContentHasChanged: boolean;
}

// this component will also be used for editing content
export default function AddContent({
  editing = false,
  cloning = false,
}: {
  editing?: boolean;
  cloning?: boolean;
}) {
  const isAdult: boolean = getJWTDecoded().isAdult;

  const navigate = useNavigate();
  const location = useLocation();
  const query = useQuery();
  const { googleAccessToken } = useContext<AuthContextValue>(AuthContext);
  const {
    list: collection,
    setList: setCollection,
    creatingList: creatingCollection,
    setCreatingList: setCreatingCollection,
  } = useAppContext();
  const { id } = useParams<{ id: string }>();
  const [formDefaultData, setFormData] = useState<AddFormValues>({
    contentType: "",
    link: "",
    title: "",
    description: "",
    availability: "PBEEJ",
    author: "",
    free: true,
    parentGuidanceRequired: false,
    parentGuidanceReason: "",
    statusCode: "Work in Progress",
    selectedKeywords: new Set(),
    selectedAgeRanges: new Set(),
    sourceId: null,
    sourceType: null,
  });
  const [allContentTypes, setAllContentTypes] = useState<Tag[]>([]);
  const [allKeywords, setAllKeywords] = useState<Tag[]>([]);
  const [allAgeRanges, setAllAgeRanges] = useState<Tag[]>([]);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false);
  const [readyToEdit, setReadyToEdit] = useState<boolean>(!editing && !cloning);
  const [owner, setOwner] = useState<string>("");
  const [originalGoogleDetails, setOriginalGoogleDetails] = useState<AddFormGoogleValues>({
    contentType: "",
    link: "",
    parentalGuidance: false,
    statusCode: "",
    collaborators: [],
  });
  const [invalidContentStatus, setInvalidContentStatus] = useState<boolean>(false);
  const [invalidSelectedAgeRanges, setInvalidSelectedAgeRanges] = useState<boolean>(false);
  const [uploadInProgress, setUploadInProgress] = useState<boolean>(false);
  const [contentId] = useState<number | undefined>(id ? parseInt(id) : undefined);
  const [savedSuccessfully, setSavedSuccessfully] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const { showBackArrow = false } = location.state || {};

  const setOtherTags = useCallback(
    (tagName: string, tag: any): void => {
      setFormData({
        ...formDefaultData,
        [tagName]: tag,
      });
    },
    [formDefaultData],
  );

  // form entry
  const form = useForm<AddFormValues>({ defaultValues: formDefaultData });
  const {
    formState: { errors },
  } = form;
  const linkWatch = form.watch("link");

  const sourceType = form.getValues("sourceType");
  const fileId: string | undefined =
    sourceType === SourceType.Google ? form.getValues("sourceId") : undefined;

  const userInfo = getJWTDecoded();
  const userName = `${userInfo.firstName} ${userInfo.lastName}`;

  const createdByUser = () => {
    return userName === owner;
  };

  useEffect(() => {
    setCreatingCollection(location.pathname.includes("collection"));
    if (creatingCollection) {
      document.title = "Create a Collection";
    } else {
      document.title = "Add Item";
    }
    getTagList().then((data) => {
      setAllContentTypes(data.ContentType);
      setAllKeywords(data.Keyword);
      setAllAgeRanges(data.AgeRange);
    });

    if ((editing || cloning) && contentId) {
      getContentAll(contentId).then((data) => {
        document.title = data.title;
        form.setValue("title", data.title);
        form.setValue("contentType", data.contentType.title);
        form.setValue("description", data.description || "");
        form.setValue("availability", data.availability);
        form.setValue("author", data.author || "");
        form.setValue("selectedAgeRanges", new Set(data.ageTags.map((tag) => tag.id)));
        form.setValue(
          "selectedKeywords",
          new Set(
            data.keywordTags.map(
              (tag): Tag => ({
                TagType: "Keyword",
                Id: tag.id,
                Text: tag.tagText,
              }),
            ),
          ),
        );
        form.setValue("statusCode", data.status);
        form.setValue("parentGuidanceRequired", data.parentGuidanceRequired);
        form.setValue("parentGuidanceReason", data.parentGuidanceReason);
        form.setValue("free", data.free);
        const link: string = data.link || "";
        form.setValue("link", editing ? link : "");
        form.setValue("sourceId", editing ? data.sourceId || "" : "");
        form.setValue("sourceType", editing ? data.sourceType || "" : "");
        form.setValue("collaborators", data.collaborators);

        if (data.contentType.id === 34) {
          setCreatingCollection(true);
          if (data.collectionContent) {
            setCollection(data.collectionContent);
          }
        }

        setReadyToEdit(true);
        setOwner(data.owner);

        setOriginalGoogleDetails({
          contentType: data.contentType.title,
          link: editing ? link : "",
          parentalGuidance: data.parentResource,
          statusCode: data.status,
          collaborators: data.collaborators.map(({ userId }) => userId),
        });
      });
    } else if (location.state && location.state.projectId) {
      getProjectDetails(location.state.projectId).then((data) => {
        if (data.members && data.members.length > 0) {
          form.setValue("statusCode", "Share with Some");
        }
        form.setValue("collaborators", data.members);
      });
    }
  }, []);

  function useQuery() {
    const search = location.search;

    return useMemo(() => new URLSearchParams(search), [search]);
  }

  const navigateToDiscover = (alert: AlertInfo | undefined = undefined) => {
    setCollection([]);
    setCreatingCollection(false);
    navigate(
      {
        pathname: `/`,
        search: `?${createSearchParams({
          ...getParams(query),
        })}`,
      },
      {
        state: {
          alert: alert,
        },
      },
    );
  };

  const navigateToDetails = (alert: AlertInfo | undefined = undefined) => {
    navigate(
      {
        pathname: `/details/${contentId}`,
        search: `?${createSearchParams({
          ...getParams(query),
        })}`,
      },
      {
        state: { alert, showBackArrow },
      },
    );
  };

  const navigateToProject = (projectId: number, alert: AlertInfo | undefined = undefined) => {
    navigate(
      {
        pathname: `/project/${projectId}`,
        search: `?${createSearchParams({
          ...getParams(query),
        })}`,
      },
      {
        state: {
          alert: alert,
        },
      },
    );
  };

  const deleteNewlyUploadedFileIfExists = useCallback(() => {
    if (fileId) {
      console.log("Trying to delete file from Google Drive...");
      // best effort; do not await
      GoogleClient.deleteMyDriveFile(fileId).catch(console.error);
    } else {
      console.error("No file ID found to delete");
    }
  }, [fileId]);

  const closeConfirmation = (alert: AlertInfo | undefined = undefined) => {
    setCollection([]);
    if (editing || (cloning && alert === undefined)) {
      navigateToDetails(alert);
    } else if (location.state && location.state.projectId) {
      navigateToProject(location.state.projectId, alert);
    } else {
      navigateToDiscover(alert);
    }
  };

  const getFormGoogleView = useCallback(
    (saveable: AddFormValues): AddFormGoogleView => {
      const googleDetails = {
        contentType: saveable.contentType,
        link: saveable.link,
        parentalGuidance: saveable.parentGuidanceRequired,
        statusCode: saveable.statusCode,
        collaborators: saveable.collaborators?.map(({ userId }) => userId) ?? [],
      };
      //TODO: Store google file ID in database on original creation to avoid link parsing
      const fileId = googleDetails.link.split("/d/").at(1)?.split("/").at(0);
      const resourceHasChanged = originalGoogleDetails.link !== googleDetails.link;
      const statusCodeHasChanged = originalGoogleDetails.statusCode !== googleDetails.statusCode;
      const isPublic = googleDetails.statusCode === "Available - Share with All";
      const isParentalContent =
        googleDetails.contentType === "Parent Help" || googleDetails.parentalGuidance;
      const wasParentalContent =
        originalGoogleDetails.contentType === "Parent Help" ||
        originalGoogleDetails.parentalGuidance;
      const isParentalContentHasChanged = wasParentalContent !== isParentalContent;
      if (!fileId) {
        console.error(`Unexpected Google link format: ${googleDetails.link}`);
      }
      return {
        ...googleDetails,
        fileId,
        resourceHasChanged,
        statusCodeHasChanged,
        isPublic,
        isParentalContent,
        isParentalContentHasChanged,
      };
    },
    [originalGoogleDetails],
  );

  const updateGoogleCollaborators = useCallback(
    async (googleView: AddFormGoogleView) => {
      const { fileId, resourceHasChanged, collaborators } = googleView;
      // permissions returned by google do not contain any info associating them with collaborators, so if any must be removed, all must be removed (and the updated list invited)
      const hasRemovedCollaborators = originalGoogleDetails.collaborators.some(
        (prevCollaborator) =>
          !collaborators.some((currCollaborator) => currCollaborator === prevCollaborator),
      );
      if (resourceHasChanged || hasRemovedCollaborators) {
        const [, collaboratorsEmails] = await Promise.all([
          GoogleClient.removeAllCollaborators(fileId),
          getCollaboratorsEmails(collaborators),
        ]);
        await GoogleClient.addCollaborators(fileId, collaboratorsEmails);
      } else {
        // if no collaborators need to be removed, then new ones can simply be invited
        const newCollaborators = collaborators.filter(
          (currCollaborator) =>
            !originalGoogleDetails.collaborators.some(
              (prevCollaborator) => prevCollaborator === currCollaborator,
            ),
        );
        if (newCollaborators.length > 0) {
          const newCollaboratorsEmails = await getCollaboratorsEmails(newCollaborators);
          await GoogleClient.addCollaborators(fileId, newCollaboratorsEmails);
        }
      }
    },
    [originalGoogleDetails],
  );

  const updateGoogleDocsPermissions = useCallback(
    async (saveable: AddFormValues) => {
      const saveableGoogleView = getFormGoogleView(saveable);
      const { fileId, resourceHasChanged, statusCodeHasChanged, isPublic } = saveableGoogleView;
      if (resourceHasChanged || statusCodeHasChanged) {
        if (isPublic) {
          await GoogleClient.addDomainReadPermission(fileId);
        } else {
          await GoogleClient.removeDomainReadPermission(fileId);
        }
      }
      await updateGoogleCollaborators(saveableGoogleView);
    },
    [getFormGoogleView, updateGoogleCollaborators],
  );

  const updateGoogleDrivePermissions = useCallback(
    async (saveable: AddFormValues) => {
      const saveableGoogleView = getFormGoogleView(saveable);
      const {
        fileId,
        resourceHasChanged,
        statusCodeHasChanged,
        isPublic,
        isParentalContent,
        isParentalContentHasChanged,
      } = saveableGoogleView;
      const isOnSharedDrive = await GoogleClient.isOnSharedDrive(fileId);
      // Once Drive item is public (i.e., on some Shared Drive), then we have no way (that we know of so far) to move it back to My Drive.
      // In addition, item on Shared Drive has many drive permissions, which cannot be deleted.
      // Thus, avoid any collaborator Google permissions updates for items already on Shared Drive.
      if (resourceHasChanged || statusCodeHasChanged || isParentalContentHasChanged) {
        if (isPublic) {
          // item destination is a Shared Drive
          if (!isOnSharedDrive) {
            // item is first being publicized
            await GoogleClient.removeAllCollaborators(fileId);
            await GoogleClient.addServiceAccountAsCollaborator(fileId);
          }
          // is moving to some Shared Drive (either from My Drive or from some other Shared Drive, e.g. swapping between Added Content and Parental Content)
          await GoogleClient.moveToSharedDrive(fileId, isParentalContent, isAdult);
        }
      }
      if (!isPublic) {
        if (isOnSharedDrive) {
          // If non-public but already on Shared Drive, then we cannot (so far) figure out how to move it back to My Drive.
          // But we can swap it between Shared Drives (if needed) to match its isParentalContent state.
          if (isParentalContentHasChanged) {
            await GoogleClient.moveToSharedDrive(fileId, isParentalContent, isAdult);
          }
        } else {
          // item is staying on My Drive
          await updateGoogleCollaborators(saveableGoogleView);
        }
      }
    },
    [getFormGoogleView, updateGoogleCollaborators, isAdult],
  );

  const saveContent = async () => {
    setSaving(true);
    const alert: AlertInfo = {
      type: AlertType.Success,
      dismissable: true,
    };
    try {
      if (YouTube.isValidSafeYTLink(form.getValues("link")) && !isAdult) {
        throw new Error("Restriction from adding YouTube links.");
      }
      const saveable = form.getValues() as AddFormValues;

      if (editing && contentId) {
        if (creatingCollection) {
          saveable.collectionItems = collection.map((item) => item.id);
        }
        await saveDetails(saveable, allContentTypes, contentId);
        alert.message = "Your content was successfully saved.";
      } else {
        if (creatingCollection) {
          saveable.collectionItems = collection.map((item) => item.id);
          await createCollection(saveable);
        } else {
          const contentId = await createNewContent(saveable, allContentTypes);
          if (location.state && location.state.projectId) {
            await addProjectContent(
              location.state.projectId,
              contentId,
              location.state.type,
              googleAccessToken,
            );
          }
        }
        alert.message = "Your content was successfully created.";
      }

      // currently, all supported Google Workspace content types (which excludes Sites) are served from docs.google.com
      if (saveable.link.includes("docs.google.com")) {
        await updateGoogleDocsPermissions(saveable);
      } else if (saveable.link.includes("drive.google.com")) {
        await updateGoogleDrivePermissions(saveable);
      }

      setSavedSuccessfully(true);
      setSaving(false);
    } catch (e) {
      console.error("failed to save content: ", e);
      alert.type = AlertType.Error;
      alert.message = "Sorry. Something went wrong. Please try that again.";
    }

    closeConfirmation(alert);
  };

  const handleDelete = async (id: number | undefined) => {
    if (id) {
      const alert: AlertInfo = {
        type: AlertType.Success,
        dismissable: true,
      };
      try {
        await deleteContent(id);
        if (fileId) {
          // Try to delete file from Google (My Drive or Shared Drive) as "best effort" (do not await).
          const link = form.getValues("link");
          if (link.includes("docs.google.com")) {
            // All Google Workspace items (even public ones) are on My Drive.
            GoogleClient.deleteMyDriveFile(fileId);
          } else {
            const isOnSharedDrive = await GoogleClient.isOnSharedDrive(fileId);
            if (isOnSharedDrive) {
              deleteSharedDriveFile(fileId);
            } else {
              GoogleClient.deleteMyDriveFile(fileId);
            }
          }
        }
        setCreatingCollection(false);
        alert.message = "Your content was successfully deleted.";
      } catch {
        alert.type = AlertType.Error;
        alert.message = "Sorry. Something went wrong. Please try that again.";
      }

      navigateToDiscover(alert);
    }
  };

  useEffect(() => {
    if (isYouTubeChannelLink(linkWatch)) {
      form.setValue("parentGuidanceRequired", true);
      setOtherTags("parentGuidanceRequired", true);
    }
  }, [linkWatch, form]);

  return readyToEdit ? (
    <Box sx={{ flexGrow: 1 }}>
      <form
        onSubmit={form.handleSubmit(() => {
          saveContent();
        })}
      >
        <ReactRouterPrompt when={form.formState.isDirty && !savedSuccessfully}>
          {({ isActive, onConfirm, onCancel }) =>
            isActive ? (
              <CloseConfirmation
                keepEditing={onCancel}
                loseChanges={() => {
                  deleteNewlyUploadedFileIfExists();
                  onConfirm();
                }}
              />
            ) : null
          }
        </ReactRouterPrompt>
        <AppBar
          position={"sticky"}
          sx={{ backgroundColor: "white", color: "black", marginBottom: "10px" }}
        >
          <Toolbar>
            <Stack spacing={1} direction={"row"} sx={{ width: "100%" }}>
              <IconButton
                onClick={() => closeConfirmation()}
                size='small'
                edge='start'
                color='inherit'
                sx={{ margin: "auto 0 !important" }}
              >
                <CloseIcon />
              </IconButton>
              <Typography variant='h6' component='div' sx={{ flexGrow: 1 }}>
                {editing
                  ? "Edit"
                  : cloning
                    ? "Clone Item"
                    : creatingCollection
                      ? "Create a Collection"
                      : "Add Item"}
              </Typography>
              {createdByUser() && (
                <IconButton onClick={() => setShowDeleteConfirmation(true)}>
                  <DeleteForeverIcon />
                </IconButton>
              )}
              <Button
                onClick={() => closeConfirmation()}
                color={"primary"}
                variant={"outlined"}
                size={"small"}
                disabled={uploadInProgress}
                sx={{ margin: "auto 0 !important", marginLeft: "0.5em !important" }}
              >
                Cancel
              </Button>
              <Button
                color={"success"}
                variant={"contained"}
                size={"small"}
                type={"submit"}
                disabled={
                  invalidContentStatus || invalidSelectedAgeRanges || uploadInProgress || saving
                }
                sx={{ margin: "auto 0 !important", marginLeft: "0.5em !important" }}
              >
                Save
              </Button>
            </Stack>
          </Toolbar>
        </AppBar>
        {showDeleteConfirmation && (
          <DeleteConfirmation
            cancel={() => setShowDeleteConfirmation(false)}
            confirm={() => handleDelete(contentId)}
          />
        )}
        <Container>
          <Stack spacing={1}>
            <Controller
              control={form.control}
              name='title'
              render={({ field, fieldState: { error } }) => {
                return (
                  <>
                    <TextField
                      error={!!error}
                      {...form.register("title", { required: true })}
                      id='title'
                      label='Title'
                      variant='standard'
                    />
                    {error && (
                      <FormHelperText id='title'>Please provide a unique title</FormHelperText>
                    )}
                  </>
                );
              }}
            />
            {!creatingCollection && (
              <TextField
                id='author'
                label='Author'
                variant='standard'
                {...form.register("author")}
              />
            )}
            <TextField
              id='description'
              label='Description'
              InputLabelProps={{ shrink: editing ? true : undefined }}
              variant='standard'
              {...form.register("description")}
              multiline
            />
            {creatingCollection ? (
              <Collection />
            ) : (
              <ExistingContent
                setOtherTags={setOtherTags}
                form={form}
                editing={editing}
                sourceId={form.getValues("sourceId") || undefined}
                setUploadInProgress={setUploadInProgress}
                deleteFile={deleteNewlyUploadedFileIfExists}
              />
            )}
            <AdultOnly>
              <FormControlLabel
                id={"parentGuidanceRequired"}
                {...form.register("parentGuidanceRequired")}
                control={
                  <Checkbox
                    checked={form.getValues("parentGuidanceRequired")}
                    onChange={() => {
                      setOtherTags(
                        "parentGuidanceRequired",
                        form.getValues("parentGuidanceRequired"),
                      );
                      form.setValue(
                        "parentGuidanceRequired",
                        !form.getValues("parentGuidanceRequired"),
                      );
                    }}
                    name='parentGuidanceRequired'
                  />
                }
                label='Parental Guidance Needed'
              />
              {form.getValues("parentGuidanceRequired") && (
                <>
                  <TextField
                    id='parentGuidanceReason'
                    label='Why is this resource marked Parental Guidance?'
                    InputLabelProps={{ shrink: editing ? true : undefined }}
                    variant='standard'
                    {...form.register("parentGuidanceReason", {
                      required:
                        "Please provide a reason why this resource was marked Parental Guidance",
                    })}
                    multiline
                  />
                  {errors.parentGuidanceReason && (
                    <div className='text-left font-medium text-red-600'>
                      {errors.parentGuidanceReason.message}
                    </div>
                  )}
                </>
              )}
            </AdultOnly>
            <ContentStatus form={form} setFormError={setInvalidContentStatus} />
            <KeywordsAndAgeRanges
              form={form}
              setFormError={setInvalidSelectedAgeRanges}
              keywords={allKeywords}
              ageRanges={allAgeRanges}
            />
          </Stack>
        </Container>
      </form>
    </Box>
  ) : (
    <div>Loading...</div>
  );
}
